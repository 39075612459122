import * as ReactDOM from 'react-dom/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { RouterProvider } from 'react-router-dom'
import theme from './theme'
import router from './routes'
import env from './env'

if (process.env.NODE_ENV === 'production') {
    console.log(env.api.url)
    if (!env.api.url) throw Error('API URL not found in production environment')
}

const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement)

root.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
    </ThemeProvider>,
)
