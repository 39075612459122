import { createBrowserRouter } from 'react-router-dom'
import App from './App'

export default createBrowserRouter([
    {
        path: '/',
        element: <>OK</>,
    },
    {
        path: '/transaction',
        element: <App />,
    },
])
