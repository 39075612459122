function clean(value) {
    if (!value || ['', 'null', 'false', 'undefined'].includes(value))
        return undefined
    return value
}

const is = {
    prod: process.env.NODE_ENV === 'production',
    test: process.env.NODE_ENV === 'test',
    dev: process.env.NODE_ENV === 'development',
}

const api_url =
    clean(
        process.env.API_URL
            ? process.env.API_URL
            : process.env.REACT_APP_API_URL,
    ) || undefined

const env = {
    is: is,
    api: {
        key: clean(process.env.API_KEY),
        url: !is.prod ? 'http://localhost:8080' : api_url,
    },
}

export default env
