import * as React from 'react'
import { Grid, Collapse, TextField, Button } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import SchemeList from './SchemeList'
import env from '../env'
import Utils from '../Utils'

/* eslint-disable react/prop-types */
export default function EMIForm({
    activeStep,
    cardNumber,
    setCardNumber,
    schemeSelected,
    setSchemeSelected,
    otp,
    setOTP,
}) {
    const [refreshState, setRefresh] = React.useState(false)
    const [isLoaded, setIsLoaded] = React.useState(false)
    const [error, setError] = React.useState('')
    const [items, setItems] = React.useState([])

    const refresh = () => {
        setSchemeSelected('')
        setIsLoaded(false)
        setError('')
        setItems([])
        setRefresh(!refreshState)
    }

    const handleCardChange = event => {
        const result = event.target.value.replace(/\D/g, '')
        setCardNumber(result)
    }

    const handleOTPChange = event => {
        const result = event.target.value.replace(/\D/g, '')
        setOTP(result)
    }

    const handleSchemeSelected = () => {
        const url = env.api.url + '/v1/public/schemes'
        console.log('Fetching data from ' + url)

        const onFailure = data => {
            setIsLoaded(true)
            setError(data.error ? data.error : data)
        }

        const onSuccess = data => {
            setIsLoaded(true)
            setItems(data.schemes)
        }

        Utils.fetch(
            url,
            onSuccess,
            onFailure,
            { retries: 5, interval: 10000 },
            {
                method: 'POST',
                body: btoa(
                    JSON.stringify({
                        model_id: '234532',
                        model: 'NKKJKLT/ER',
                        loan_amount: 9492.85,
                        timestamp: '2023-10-10T10:10-05:30',
                    }),
                ),
            },
        )
    }

    React.useEffect(handleSchemeSelected, [refreshState])

    return (
        <React.Fragment>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                }}
            >
                <Button onClick={refresh}>
                    <RefreshIcon />
                </Button>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="card"
                        label="Card Number"
                        autoComplete="emi-card"
                        onChange={handleCardChange}
                        value={cardNumber}
                        fullWidth
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={activeStep > 0}>
                        <SchemeList
                            error={error}
                            isLoaded={isLoaded}
                            items={items}
                            schemeSelected={schemeSelected}
                            schemeSelector={setSchemeSelected}
                        />
                    </Collapse>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={activeStep > 1}>
                        <TextField
                            required
                            id="otp"
                            label="OTP"
                            helperText="An OTP is sent to your registered mobile number"
                            autoComplete="emi-otp"
                            onChange={handleOTPChange}
                            value={otp}
                            variant="standard"
                            fullWidth
                        />
                    </Collapse>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
