import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Form from './form'

export default function App() {
    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 4 }}>
                <Form />
            </Box>
        </Container>
    )
}
