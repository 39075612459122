async function fetchData(
    url,
    successCB,
    failureCB,
    { retries = 10, interval = 10000 },
    options = {},
) {
    try {
        const res = await fetch(url, options)
        if (res.ok) {
            const encoded = await res.text()
            if (encoded) {
                const data = JSON.parse(atob(encoded))
                if (data) {
                    if (data.error) {
                        failureCB(data.error)
                        console.log('Error: ' + data.error.detail)
                    } else {
                        successCB(data)
                        console.log('Found data.')
                    }
                }
            }
        } else if (retries > 0) {
            return setTimeout(
                () =>
                    fetchData(
                        url,
                        successCB,
                        failureCB,
                        { retries: retries - 1, interval },
                        options,
                    ),
                interval,
            )
        } else {
            throw Error(res.status)
        }
    } catch (e) {
        failureCB(String(e))
        console.log(e)
    }
}

export default {
    fetch: fetchData,
}
