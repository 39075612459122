import * as React from 'react'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

/* eslint-disable react/prop-types */
export default function SchemeList({
    error,
    isLoaded,
    items,
    schemeSelected,
    schemeSelector,
}) {
    const isSelected = row => schemeSelected === row.scheme_id

    function SchemeTable() {
        return (
            <React.Fragment>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 100 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">&nbsp;</TableCell>
                                <TableCell align="center">EMI</TableCell>
                                <TableCell align="center">Tenure</TableCell>
                                <TableCell align="center">Extra Fee</TableCell>
                                <TableCell align="center">
                                    Down payment
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        onClick={() =>
                                            schemeSelector(row.scheme_id)
                                        }
                                        role="checkbox"
                                        aria-checked={isSelected(row)}
                                        tabIndex={-1}
                                        key={row.scheme_id}
                                        selected={isSelected(row)}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isSelected(row)}
                                                inputProps={{
                                                    'aria-labelledby': `table-checkbox-${index}`,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{ minWidth: 90 }}
                                        >
                                            &#8377; {row.emi_amount}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.tenure} M
                                        </TableCell>
                                        <TableCell align="center">
                                            &#8377; {row.additional_fee}
                                        </TableCell>
                                        <TableCell align="center">
                                            &#8377; {row.down_payment}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
        )
    }

    if (error) {
        return <div>{error}</div>
    } else if (!isLoaded) {
        return <div>Loading scheme list...</div>
    } else {
        return (
            <React.Fragment>
                <Typography variant="h6">Schemes</Typography>
                <SchemeTable />
            </React.Fragment>
        )
    }
}
