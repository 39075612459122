import * as React from 'react'
import {
    CssBaseline,
    Box,
    Container,
    Paper,
    Button,
    Link,
    Typography,
} from '@mui/material'
import EMIForm from './EMIForm'

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://webcoffee.in/">
                WebCoffee
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

const steps = ['Verify Card', 'Generate OTP', 'Authorize Transaction']

export default function Form() {
    const [activeStep, setActiveStep] = React.useState(0)
    const [cardNumber, setCardNumber] = React.useState('')
    const [schemeSelected, setSchemeSelected] = React.useState('')
    const [otp, setOTP] = React.useState('')

    const handleNext = () => {
        if (activeStep === 0) {
            if (String(cardNumber).length === 16) {
                setActiveStep(activeStep + 1)
            }
        } else if (activeStep === 1) {
            if (schemeSelected) {
                setActiveStep(activeStep + 1)
            }
        } else if (activeStep === 2) {
            if (String(otp).length === 6) {
                console.log('Success')
            } else {
                console.log('Invalid OTP')
            }
        }
    }

    const handleBack = () => {
        if (activeStep > 0) setActiveStep(activeStep - 1)
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper
                    variant="outlined"
                    sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
                >
                    <Typography
                        component="h1"
                        variant="h4"
                        align="center"
                        sx={{ my: 3 }}
                    >
                        Bajaj FinServ No Cost EMI
                    </Typography>
                    <hr />
                    <React.Fragment>
                        <EMIForm
                            activeStep={activeStep}
                            cardNumber={cardNumber}
                            setCardNumber={setCardNumber}
                            schemeSelected={schemeSelected}
                            setSchemeSelected={setSchemeSelected}
                            otp={otp}
                            setOTP={setOTP}
                        />
                        <Box
                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            {activeStep !== 0 && (
                                <Button
                                    onClick={handleBack}
                                    sx={{ mt: 3, ml: 1 }}
                                >
                                    Back
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{ mt: 3, ml: 1 }}
                            >
                                {steps[activeStep]}
                            </Button>
                        </Box>
                    </React.Fragment>
                </Paper>
                <Copyright />
            </Container>
        </React.Fragment>
    )
}
